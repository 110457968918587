<template>
    <main class="min-h-screen w-full bg-lightBackground dark:bg-darkBackground text-lightText dark:text-darkText transition-colors duration-300">
        <div class="mx-auto max-w-screen-xl">
            <div :class="[asideOpen ? 'content-shrink' : 'content-expand', 'mx-auto max-w-5xl']">
                <AppHeader @toggleSidebar="toggleSidebar"/>
                <div :class="[asideOpen ? 'content-shrink' : 'content-expand', 'flex flex-row gap-4 px-4']">
                    <AppSidebar 
                        :asideOpen="asideOpen" 
                        class="flex-shrink-0 sidebar"
                        @toggleSidebar="toggleSidebar" 
                        @proposeText="openProposalModal" 
                        @closeModal="closeModal"
                        @update:userText="user_text = $event"
                    />
                    <div :class="[asideOpen ? 'content-shrink' : 'content-expand', 'flex-grow min-h-screen pb-40']">
                      <PreselectedQuestionsContainer
                        :title="$t('Body_Section1_Title')"
                        :subtitle="$t('Body_Section1_Subtitle')"
                        :hasChatResponses="chat_responses.length > 0"
                        :preselectedQuestions="PreselectedQuestions"
                        :asideOpen="asideOpen"
                        @edit="openEditModal"
                        @selectQuestion="handlePreselectedQuestion"
                      />
                      <div v-for="chat_response in chat_responses" :key="chat_response.id" class="max-w-full overflow-hidden break-words">
                        <ChatResponseItem :chatResponse="chat_response" class="max-w-full"/>
                      </div>
                        <DeleteChatButton 
                            v-if="chat_responses.length > 0 && !responding" 
                            :chatResponses="chat_responses"
                            :responding="responding"
                            :scrolling="scrolling"
                            :itemIndex="itemIndex"
                            @delete-chat="handleDeleteChat"
                        />
                    </div>
                </div>
                <ScrollToTopButton />
            </div>
        </div>
        <footer :class="[asideOpen ? 'content-shrink' : 'content-expand', 'fixed bottom-0 left-0 w-full bg-white dark:bg-darkBackground']">
            <ScrollToBottomButton />
            <FooterComponent
                :categories="translatedCategories"
                :preselectedQuestions="PreselectedQuestions"
                :componentKey="componentKey"
                :selectedCategory="selectedCategory"
                :user_text="user_text"
                :responding="responding"
                @update:category="handleCategoryUpdate"
                @update:userText="user_text = $event"
                @send="handleSend"
                @stop="stopResponse"
                @edit="openEditModal"
                />
        </footer>   
        <teleport to="body">
          <EditModal 
              v-if="showEditModal" 
              :initialQuestions="PreselectedQuestions" 
              :categories="translatedCategories" 
              @close="closeEditModal" 
              @questionsUpdated="handleUpdatedQuestions" 
              />
        </teleport>    
        <teleport to="body">
          <ProposalModal v-if="modal_expanded" @close="closeModal" />
        </teleport> 
    </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import { useI18n } from 'vue-i18n'; 
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from '@/authConfig';
import { initFlowbite } from 'flowbite';
import { VUE_APP_API_URL } from '@/constants';
import AppHeader from './layout/AppHeader.vue';
import AppSidebar from './layout/AppSidebar.vue';
import PreselectedQuestionsContainer from './ui/PreselectedQuestionsContainer.vue';
import ChatResponseItem from './chat/ChatResponseItem.vue';
import DeleteChatButton from './chat/DeleteChatButton.vue';
import ScrollToTopButton from './buttons/ScrollToTopButton.vue';
import ScrollToBottomButton from './buttons/ScrollToBottomButton.vue';
import EditModal from './modals/EditModal.vue';
import ProposalModal from './modals/ProposalModal.vue';
import FooterComponent from './layout/footer/FooterComponent.vue';
import MarkdownIt from 'markdown-it';
import { watch } from 'vue';

const { t, locale } = useI18n();
const { acquireToken, result} = useMsalAuthentication(InteractionType.Redirect, loginRequest);
const asideOpen = ref(false);
const modal_expanded = ref(false);
const showEditModal = ref(false);
const scrolling = ref(false);
const scrolling_down = ref(false);
const responding = ref(false);
const chat_responses = ref([]);
const user_text = ref('');
const componentKey = ref(0);
const selectedCategory = ref(null);
// const authResult = ref(null);
const abortController = ref(null);
const itemIndex = ref(0);

const showDocsReferences = ref(false);
const contentLevel = ref(0);


const handleDeleteChat = () => {
  chat_responses.value = [];
  itemIndex.value = 0;
  responding.value = false;
  scrolling.value = false;
};

const categories = ref([
    { text: 'Categories_General', id: "general" },
    { text: 'Categories_Prospeccion', id: "prospeccion" },
    { text: 'Categories_Preparacion', id: "preparacion" },
    { text: 'Categories_Apertura', id: "apertura" },
    { text: 'Categories_Exploracion', id: "exploracion" },
    { text: 'Categories_Propuesta', id: "propuesta" },
    { text: 'Categories_SuperacionObjeciones', id: "superacion-de-objeciones" },
    { text: 'Categories_Cierre', id: "cierre" },
    { text: 'Categories_Seguimiento', id: "seguimiento" },
]);

const translatedCategories = computed(() => {
  return categories.value.map(category => ({
    ...category,
    text: category.text
  }));
});


const handleCategoryUpdate = (newCategory) => {
  selectedCategory.value = newCategory;

  if (newCategory.id === 'propuesta') {
    getResponse(t('Proposal_Message_Introduction_Html_3'), newCategory.id, newCategory.text);
  }
};

const handleUpdatedQuestions = (updatedQuestions) => {
  PreselectedQuestions.value = updatedQuestions;
  savePreselectedQuestions();
};

const PreselectedQuestions = ref([
  { textKey: 'Body_Section1_PreselectedText_1', categoryId: 'preparacion', text: '' },
  { textKey: 'Body_Section1_PreselectedText_2', categoryId: 'superacion-de-objeciones', text: '' },
  { textKey: 'Body_Section1_PreselectedText_3', categoryId: 'cierre', text: '' },
]);

const loadPreselectedQuestions = () => {
  const storedQuestions = localStorage.getItem(`preselectedQuestions_${locale.value}`);
  if (storedQuestions) {
    PreselectedQuestions.value = JSON.parse(storedQuestions);
  } else {
    PreselectedQuestions.value = [
      { textKey: 'Body_Section1_PreselectedText_1', categoryId: 'preparacion', text: t('Body_Section1_PreselectedText_1') },
      { textKey: 'Body_Section1_PreselectedText_2', categoryId: 'superacion-de-objeciones', text: t('Body_Section1_PreselectedText_2') },
      { textKey: 'Body_Section1_PreselectedText_3', categoryId: 'cierre', text: t('Body_Section1_PreselectedText_3') },
    ];
    savePreselectedQuestions();
  }
};

const savePreselectedQuestions = () => {
  localStorage.setItem(`preselectedQuestions_${locale.value}`, JSON.stringify(PreselectedQuestions.value));
};

const toggleSidebar = () => {
    asideOpen.value = !asideOpen.value;
};

const closeModal = () => {
    modal_expanded.value = false;
};

const openProposalModal = () => {
    modal_expanded.value = true;
};
const handlePreselectedQuestion = (question) => {
    if (question && question.text) {
        user_text.value = question.text;        
        const selectedCategory = categories.value.find(cat => cat.id === question.categoryId);
        const translatedCategoryText = selectedCategory ? t(selectedCategory.text) : '';

        getResponse(question.text, question.categoryId, translatedCategoryText);
    }
};

const openEditModal = () => {
    showEditModal.value = true;
};

const closeEditModal = () => {
    showEditModal.value = false;
};

const handleSend = ({ message, category }) => {
  getResponse(message, category.id, category.text);
};

const getResponse = async (text, selectedCategoryid, selectedCategorytext) => {
  if (responding.value) return;
  if (!text || text.trim() === "") return;

  user_text.value = "";
  asideOpen.value = false;

  const previousProposalMessages = chat_responses.value.filter(
    (item) => item.categoryid === "propuesta"
  );

  if (selectedCategoryid === "propuesta" && previousProposalMessages.length === 0) {
    let chatItem = {
      id: itemIndex.value,
      categoryid: selectedCategoryid,
      category: selectedCategorytext,
      question: text,
      answer: ""
    };

    chat_responses.value.push(chatItem);
    itemIndex.value++;

    responding.value = true;

    const proposal_message = (t('Proposal_Message_Introduction_Html') + '<br><br>' + t('Proposal_Message')).replace(/\n/g, '<br>');

    const words = proposal_message.split(" ");
    let counterWords = 0;
    let lastResponse = chat_responses.value[itemIndex.value - 1];
    let max_time = new Date().getTime() + 1200;

    for (let i = 0; i < words.length; i++) {
      counterWords++;
      lastResponse.answer += ` ${words[i]}`;

      if (counterWords > 4) {
        await new Promise(resolve => setTimeout(resolve, 100));
        if (new Date().getTime() > max_time) {
          lastResponse.answer = proposal_message;
          chat_responses.value[itemIndex.value - 1] = lastResponse;
          break;
        }

        max_time = new Date().getTime() + 1200;
        chat_responses.value[itemIndex.value - 1] = lastResponse;
        scrollToEnd();
        counterWords = 0;
      }
    }

    responding.value = false;
    scrollToEnd();
  } 
  else {
    let chatItem = {
      id: itemIndex.value,
      categoryid: selectedCategoryid,
      category: selectedCategorytext,
      question: text,
      answer: "",
      rawanswer: ""
    };

    const body = {
      user_query: chatItem.question,
      chat_history: getChatHistory(),
      copilot_category: chatItem.categoryid,
      lang: locale.value
    };

    chat_responses.value.push(chatItem);
    await sleep(100);
    scrollToEnd();

    itemIndex.value++;
    responding.value = true;
    abortController.value = new AbortController();
    const { signal } = abortController.value;

    try {
      await acquireToken();

      const response = await fetch(`${VUE_APP_API_URL}/api/aci/streaming`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${result.value.accessToken}`
        },
        body: JSON.stringify(body),
        signal: signal
      });

      if (!response.ok) {
        await writeReponseStreamingAsync("Error en la respuesta del servidor.");
        responding.value = false;
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let finished = false;

      while (!finished) {
        const { done, value } = await reader.read();
        if (done) break;

        finished = done;
        let item = decoder.decode(value).replace(/^\[|]$/g, '').replace(/^,|,$/g, '');

        const list = JSON.parse("[" + item + "]");
        let text = list.join('');

        await writeReponseStreamingAsync(text);
        scrollToEnd(true);
      }

      responding.value = false;
      scrollToEnd(true);
      reader.releaseLock();
    } catch (error) {
      if (error.name !== 'AbortError') {
        await writeReponseStreamingAsync("Error en la respuesta del servidor.");
      }
      responding.value = false;
      scrollToEnd(true);
    }
  }
};


const stopResponse = () => {
  if (abortController.value) {
    abortController.value.abort();    
    const lastResponse = chat_responses.value[itemIndex.value - 1];
    
    if (lastResponse && lastResponse.answer === "") {
      lastResponse.rawanswer = " ";
      lastResponse.answer = " ";
    }

    responding.value = false;
    scrollToEnd(true);
  }
};

const scrollToEnd = (onlyIfAtBottom = false) => {
    if (onlyIfAtBottom != true || ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 60)) {
        nextTick(() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
            });
        });
    }
};

const adjustFooterMargin = () => {
    const footer = document.querySelector('footer');
    const mainContent = document.querySelector('#content-wrap');
    if (footer && mainContent) {
        const footerHeight = footer.offsetHeight;
        mainContent.style.boxSizing = 'border-box';
        mainContent.style.paddingBottom = `${footerHeight}px`;
    }
};

const handleScroll = () => {
    const container = document.getElementsByClassName('scroll-down-container')[0];
    const triggerPoint = container.getBoundingClientRect().bottom + 300;

    if (document.documentElement.scrollTop > triggerPoint) {
        scrolling_down.value = true;
    } else {
        scrolling_down.value = false;
    }

    if (document.documentElement.scrollTop + document.documentElement.clientHeight < document.documentElement.offsetHeight - 60) {
        scrolling.value = true;
    } else {
        scrolling.value = false;
    }
};

const getChatHistory = () => {
    const questionStory = [];
    const answerStory = [];

    chat_responses.value.forEach(chatResponse => {
        questionStory.push(chatResponse.question);
        answerStory.push(chatResponse.answer);
    });

    return {
        user_queries: questionStory,
        copilot_responses: answerStory
    };
};

const writeReponseStreamingAsync = async (answerResponse) => {
    const lastResponse = chat_responses.value[itemIndex.value - 1];
    if (lastResponse) {
        responding.value = true;
        
        const markdown = new MarkdownIt({
            html: true,
            breaks: true,
            langPrefix: 'language-', 
            linkify: true,
            typographer: true,
            quotes: '“”‘’'
        });

        lastResponse.rawanswer += answerResponse;
               
        let textToRender = lastResponse.rawanswer
                .replace(/\n/g, '<br/>')
                .replace(/^\s+-/gm, '-');

        lastResponse.answer = markdown.render(textToRender);
        
        chat_responses.value[itemIndex.value - 1] = lastResponse;
    }
};

const sleep = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay));
};

watch(locale, () => {
    loadPreselectedQuestions(); 
});

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    window.addEventListener('resize', adjustFooterMargin);

    initFlowbite();
    adjustFooterMargin();
    loadPreselectedQuestions();
    // authResult.value
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleScroll);
    window.removeEventListener('resize', adjustFooterMargin);
});
</script>



<style scoped>
.content-shrink {
    margin-left: 15vh;
    transition: margin-left 0.3s ease;
}

.content-expand {
    margin-left: 0;
    transition: margin-left 0.3s ease;
}

.chat-response-item {
    word-wrap: break-word;
    white-space: pre-wrap;
}

li {
    list-style-type: disc;
    margin-left: 2em;
}
</style>
